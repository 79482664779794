<template>
  <list-template
      :loading="loading"
      :search-config="searchConfig"
      @onSearch="onSearch"
      :isDownload="true"
      @onExportData="onExport"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :current-page="page"
      @onChangePage="val=>{$store.commit('setPage',val);getData()}"
  ></list-template>
</template>

<script>
import {mapState} from "vuex";

export default {
  _config:{"route":{"path":"index","meta":{"title":"统计"}}},
  computed: {
    ...mapState(['page'])
  },
  data(){
    return {
      loading:true,
      total:0,
      search:{},
      searchConfig:[
        {prop:'status',placeholder:'筛选状态',tag:"select",options:[{
          label:"已启用",
          value:1
        },{
          label:"已禁用",
          value:0
          }]},
        {prop:'number',placeholder:'最低使用次数', tag:"number",min:0,step:1,"stepStrictly":true
        }
      ],
      tableData:[],
      tableConfig:[
        {prop:"name",label:"词条"},
        {prop:"number",label:"被使用次数"},
        {prop:"status",label:"当前状态"},
      ]
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    getData(isInit=false){
      let page = this.page;
      if (isInit){
        this.$store.commit("setPage",1);
        page = 1;
      }
      let params = {...this.search, page};
      this.loading = true;
      this.$_axios2.get("api/teacher-entry/quote-num",{logic:1,params}).then(res=>{
        let {list,page:p} = res.data;
        this.tableData = list;
        this.total = p.total;
      }).finally(()=>this.loading = false)
    },
    onExport(){
      this.$_axios2.get("api/teacher-entry/quote-num-export",{params:this.search}).then(res=>{
        this.$tools.download("日常表现词频统计",res.data);
      })
    },
    onSearch(val){
      this.search = val;
      this.getData(true);
    }
  }
}
</script>

<style scoped>

</style>
